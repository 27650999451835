import { z } from 'zod';
import type { SchemaErrors } from './SchemaErrors';
import { optionalNumber } from './OptionalNumber';
import { optionalString } from './OptionalString';
import { CaseMappings } from '@apparatix/utilities/CaseMappings';

export const addressSchema = z.object({
  id: optionalNumber(),
  addressLine1: optionalString,
  addressLine2: optionalString,
  addressLine3: optionalString,
  city: optionalString,
  locality: optionalString,
  region: optionalString,
  country: optionalString,
  postalCode: optionalString,
  timeZone: optionalString,
  fullAddress: optionalString,
});

export const cleanAddress = addressSchema.transform(data => Object.fromEntries(
  Object.keys(data).filter(key => Boolean(data[key])).map(key => [key, data[key]]),
));

export const addressToSnake = addressSchema.transform(data => {
  const mapper = new CaseMappings();
  return mapper.camelToSnake(data);
});

export const addressToCleanSnake = cleanAddress.pipe(addressToSnake);

export type AddressSchema = z.infer<typeof addressSchema>;

export const emailSchema = z.object({
  id: optionalNumber(),
  email: z.string().email({ message: 'Please enter a valid email address' }).optional(),
  addressType: z.string().default(() => 'work'),
});

export const cleanEmail = emailSchema.transform(data => data.email ? data : undefined);

export type EmailSchema = z.infer<typeof emailSchema>;

export const phoneNumberSchema = z.object({
  id: optionalNumber(),
  addressType: z.string().default(() => 'work'),
  number: z.string().optional(),
});

export const cleanPhoneNumber = phoneNumberSchema.transform(data => data.number ? data : undefined);

export type PhoneNumberSchema = z.infer<typeof phoneNumberSchema>;

export const webPageSchema = z.object({
  id: optionalNumber(),
  addressType: z.string().default(() => 'work'),
  url: z.string().url({ message: 'Please enter a valid URL' }).optional(),
});

export const cleanWebPage = webPageSchema.transform(data => data.url ? data : undefined);

export type WebPageSchema = z.infer<typeof webPageSchema>;

export const addressableDetailSchema = z.object({
  addresses: addressSchema.array().optional(),
  emails: emailSchema.array().optional(),
  phoneNumbers: phoneNumberSchema.array().optional(),
  webPages: webPageSchema.array().optional(),
});

export const addressableToPayload = addressableDetailSchema.transform(data => ({
  addresses: data?.addresses?.map(address => addressToCleanSnake.parse(address))?.filter(address => Object.keys(address).length > 0),
  // eslint-disable-next-line camelcase
  address_emails: data?.emails?.map(email => cleanEmail.parse(email))?.filter(email => email !== undefined),
  // eslint-disable-next-line camelcase
  address_phone_numbers: data?.phoneNumbers?.map(phone => cleanPhoneNumber.parse(phone))?.filter(phone => phone !== undefined),
  // eslint-disable-next-line camelcase
  address_web_pages: data?.webPages?.map(page => cleanWebPage.parse(page))?.filter(page => page !== undefined),
}));

export type AddressableDetailSchema = z.infer<typeof addressableDetailSchema>;

export type AddressableDetailErrors = SchemaErrors<AddressableDetailSchema>;
