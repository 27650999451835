import { ref, type Ref } from 'vue';
import { useFetch } from './useFetch';
import { useToastNotifications } from './useToastNotifications';

type FetchType = 'GET' | 'POST' | 'PUT' | 'DELETE';

interface EndpointOptions<T = object, U = object> {
  url?: string;
  type: FetchType;
  successField: keyof T;
  onSuccess?: (responseData?: T, args?: any) => void;
  onError?: (errorData?: U) => void;
  optimisticStateUpdate?: Array<[Ref<any>, any]>;
  rollBackStateOnError?: boolean;
  isNullResponseError?: boolean;
  startInLoadingState?: boolean;
  toast?: ToastOptions;
}

interface ToastOptions {
  showOnSuccess?: boolean;
  showOnError?: boolean;
  successTitle?: string;
  errorTitle?: string;
  successMessage?: string;
  errorMessage?: string;
}

const toastDefaults: ToastOptions = {
  showOnError: true,
  showOnSuccess: true,
};


export const useEndpoint = <T = object, U = object>(options: EndpointOptions<T, U>) => {
  const opts: EndpointOptions<T, U> = {
    ...{
      toast: toastDefaults, isNullResponseError: true, rollBackStateOnError: true, startInLoadingState: false,
    },
    ...options,
  };
  const _fetch = useFetch();
  const _toast = useToastNotifications();

  const isLoading = ref(opts.startInLoadingState);
  const previousState = ref<{[key: number]: any }>({});
  const endpointUrl = ref(opts.url);
  const didError = ref(false);

  const onError = (errorData?: U) => {
    didError.value = true;
    rollBackState();
    if (typeof opts.onError === 'function') {
      opts.onError(errorData);
    }

    if (opts.toast?.showOnError) {
      _toast.showError(opts.toast?.errorMessage, opts.toast?.errorTitle);
    }
  };

  const onSuccess = <TArgs extends object = object>(responseData?: T, args?: TArgs) => {
    didError.value = false;
    if (typeof opts.onSuccess === 'function') {
      opts.onSuccess(responseData, args);
    }

    if (opts.toast?.showOnSuccess) {
      _toast.showSuccess(opts.toast?.successMessage, opts.toast?.successTitle);
    }
  };

  const performOptimisticStateUpdate = () => {
    opts.optimisticStateUpdate?.forEach((item, idx) => {
      const [itemRef, newValue] = item;
      const refType = typeof itemRef.value;
      if (refType === 'number' || refType === 'boolean' || refType === 'string') {
        const copy = itemRef.value;
        previousState.value[idx] = copy;
      } else {
        previousState.value[idx] = structuredClone(itemRef.value);
      }

      itemRef.value = newValue;
    });
  };

  const rollBackState = () => {
    if (!opts.rollBackStateOnError) return;

    opts.optimisticStateUpdate?.forEach((item, idx) => {
      const [itemRef] = item;
      itemRef.value = previousState.value[idx];
    });

    previousState.value = {};
  };

  const determineSuccess = <TArgs extends object = object>(result?: T | U, args?: TArgs) => {
    if (!result && opts.isNullResponseError) {
      onError(result as U);
      return false;
    }
    if (!result && !opts.isNullResponseError) {
      onSuccess(null, args);
      return true;
    }
    if ((result as T)[opts.successField]) {
      onSuccess(result as T, args);
      return true;
    }
    onError(result as U);
    return false;
  };

  const makeRequest = async <TPayload, TSuccessArgs extends object = object>(payload?: TPayload, additionalSuccessArgs?: TSuccessArgs) => {
    previousState.value = {};
    isLoading.value = true;
    performOptimisticStateUpdate();

    try {
      switch (opts.type) {
        case 'GET':
          determineSuccess(await _fetch.get<T>(endpointUrl.value), additionalSuccessArgs);
          break;
        case 'POST':
          determineSuccess(await _fetch.post<T, TPayload>(endpointUrl.value, payload), additionalSuccessArgs);
          break;
        case 'PUT':
          determineSuccess(await _fetch.put<T, TPayload>(endpointUrl.value, payload), additionalSuccessArgs);
          break;
        case 'DELETE':
          determineSuccess(await _fetch.destroy<T>(endpointUrl.value, payload), additionalSuccessArgs);
          break;
        default:
          break;
      }
    } catch (e) {
      if (import.meta.env.DEV) {
        console.error(e);
      }
      onError();
    } finally {
      isLoading.value = false;
    }
  };

  const getResults = async <TResponse extends T, TPayload extends object = object>(payload?: TPayload, type: FetchType = opts.type): Promise<TResponse> => {
    isLoading.value = true;
    let result: TResponse;
    try {
      switch (type) {
        case 'GET':
          result = await _fetch.get<TResponse>(endpointUrl.value);
          break;
        case 'POST':
          result = await _fetch.post<TResponse>(endpointUrl.value, payload);
          break;
        case 'PUT':
          result = await _fetch.put<TResponse>(endpointUrl.value, payload);
          break;
        case 'DELETE':
          result = await _fetch.destroy(endpointUrl.value, payload);
          break;
        default:
          return null;
      }
    } catch {
      onError();
      return null;
    } finally {
      isLoading.value = false;
    }

    const success = determineSuccess(result);

    return success ? result : null;
  };

  const setEndpointUrl = (url: string) => endpointUrl.value = url;

  return {
    isLoading, makeRequest, setEndpointUrl, didError, getResults,
  };
};
