const snakeToCamel = <T extends Record<string, any>, U>(from: T): U => {
  if (typeof from !== 'object' || from === null) return undefined;

  if (Array.isArray(from)) {
    return from.map(snakeToCamel) as U;
  }

  return Object.fromEntries(
    Object.entries(from).map(([key, value]) => [
      key.replace(/_([a-zA-Z])/g, (_, letter) => letter.toUpperCase()),
      value,
    ]),
  ) as U;
};

const camelToSnake = <T extends Record<string, any>, U>(from: T): U => {
  if (typeof from !== 'object' || from === null) return undefined;

  if (Array.isArray(from)) {
    return from.map(camelToSnake) as U;
  }

  return Object.fromEntries(
    Object.entries(from).map(([key, value]) => [
      key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`),
      value,
    ]),
  ) as U;
};

export class CaseMappings {
  public snakeToCamel<T extends Record<string, any>, U>(from: T): U {
    return snakeToCamel(from);
  }

  public camelToSnake<T extends Record<string, any>, U>(from: T): U {
    return camelToSnake(from);
  }
}
