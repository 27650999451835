
import { z } from 'zod';
import { leadDetail } from './LeadDetail';
import { newAccountSchema } from './Account';
import { newContactSchema } from './Contact';
import { addressableToPayload } from './Addressable';
import { optionalString } from './OptionalString';
import { CaseMappings } from '@apparatix/utilities/CaseMappings';

export const wizardNewContact = z.object({
  account: newAccountSchema.merge(z.object({
    contactKey: z.string().optional(),
    name: optionalString,
    type: optionalString,
  })).optional().default(() => ({
    name: undefined,
  })),
  contactLabels: z.object({
    contactLabelId: z.number().positive(),
    contactLabelDisplayName: z.string(),
  })
    .array()
    .optional()
    .default(() => []),
  ...newContactSchema._def.schema.shape,
});

export const newContactPayloadTransformer = wizardNewContact.transform(data => {
  const tmp = { ...data };
  delete tmp.key;
  delete tmp.account;

  const mapper = new CaseMappings();

  const snake: object = mapper.camelToSnake(tmp);

  return {
    ...snake,
    ...addressableToPayload.parse(data),
  };
});

export const leadWizardState = leadDetail.extend({
  name: optionalString,
  notes: z.string().optional(),
  type: z.literal('Lead'),
  contacts: wizardNewContact
    .array()
    .min(1, { message: 'Please add a Contact before creating this Lead' })
    .optional()
    .default(() => []),
  accounts: newAccountSchema.array().max(2, { message: 'No more Accounts can be associated with this Lead' })
    .optional()
    .superRefine((values, ctx) => {
      const hasAgency = Boolean(values?.find(account => account.type === 'Agency'));
      const hasAccount = Boolean(values?.find(account => account.type === 'Lead' || account.type === 'Advertiser'));

      if (hasAgency && !hasAccount) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please add an Advertiser Account before creating this Lead',
          path: ['accounts'],
        });
      }
    })
    .default(() => []),
});

export const transformStateToPayload = leadWizardState.transform(data => {
  const tmp = { ...data };
  delete tmp.accounts;
  delete tmp.contacts;
  delete tmp.owner;

  const opportunity = {
    ...tmp,
    accountId: data.accounts?.find(account => account.type === 'Lead' || account.type === 'Advertiser')?.id,
    agencyId: data.accounts?.find(account => account.type === 'Agency')?.id,
    status: data.status?.id,
    temperature: data.temperature?.id,
    ownerId: data.owner.id,
    source: data.source,
    contacts: data.contacts.filter(contact => Boolean(contact.id)).map(contact => ({
      id: contact.id,
      labelIds: contact.contactLabels?.map(label => label.contactLabelId),
    })),
  };

  return {
    data: {
      opportunity: Object.fromEntries(
        Object.keys(opportunity)
          .filter(key => opportunity[key] !== undefined)
          .map(key => [key, opportunity[key]]),
      ),
    },
  };
});

export type WizardNewContact = z.infer<typeof wizardNewContact>;
export type LeadWizardState = z.infer<typeof leadWizardState>;
