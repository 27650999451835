/* eslint-disable camelcase */
import { z } from 'zod';
import type { SchemaErrors } from './SchemaErrors';
import {
  addressSchema,
  phoneNumberSchema,
  webPageSchema,
  emailSchema,
} from './Addressable';
import { optionalNumber } from './OptionalNumber';
import { CaseMappings } from '@apparatix/utilities/CaseMappings';
import { optionalString } from './OptionalString';

export const contactSchema = z.object({
  id: optionalNumber(),
  salutation: optionalString,
  firstName: optionalString,
  middleName: optionalString,
  lastName: optionalString,
  fullName: optionalString,
  suffix: optionalString,
  nickname: optionalString,
  companyName: optionalString,
  jobTitle: optionalString,
  addresses: addressSchema.array().optional(),
  phoneNumbers: phoneNumberSchema.array().optional(),
  webPages: webPageSchema.array().optional(),
  emails: emailSchema.array().optional(),
  ownerId: optionalNumber(),
  ownerName: optionalString,
});


export const newContactSchema = contactSchema.merge(z.object({
  // A key is used as a unique identifier in lieu of an ID since the object hasn't been sent to the backend
  key: z.string().optional().default(() => crypto.randomUUID()),
}))
  .refine(data => data.fullName || data.companyName, {
    message: 'Contact must have a name, or a company name',
    path: ['fullName'],
  });

// Creates a payload to send to the backend from a contact schema object
export const newContactPayloadTransformer = newContactSchema.transform(data => {
  const tmp = { ...data };
  delete tmp.key;

  const mapper = new CaseMappings();
  const snake = mapper.camelToSnake(tmp) as object;

  return {
    data: {
      contact: {
        user_id: data.ownerId,
        ...snake,
      },
    },
  };
});

const searchResultSchema = z.object({
  addressEmails: emailSchema.merge(z.object({
    email: optionalString,
  })).array().optional(),
  addressPhoneNumbers: phoneNumberSchema.merge(z.object({
    number: optionalString,
  })).array().optional(),
  addresses: addressSchema.array().optional(),
  company_name: optionalString,
  first_name: optionalString,
  full_name: optionalString,
  id: z.number().positive(),
  job_title: optionalString,
  last_name: optionalString,
  middle_name: optionalString,
  nickname: optionalString,
  ownerId: z.number().positive(),
  ownerName: optionalString,
  salutation: optionalString,
  suffix: optionalString,
});

// Transforms contacts returned from the search endpoint into an object that matches the contact schema
export const searchResultTransformer = searchResultSchema.transform(data => {
  const mapper = new CaseMappings();
  const camel = mapper.snakeToCamel(data);
  return camel as z.infer<typeof contactSchema>;
});

export type ContactSchema = z.infer<typeof contactSchema>;
export type PendingContact = z.infer<typeof newContactSchema>;

export type ContactErrors = SchemaErrors<ContactSchema>;
