import { z } from 'zod';
import type { SchemaErrors } from './SchemaErrors';
import { addressSchema, phoneNumberSchema, webPageSchema } from './Addressable';
import { owner } from './Owner';

export const industrySchema = z.object({
  code: z.string().nonempty(),
  description: z.string().nonempty(),
});

export type IndustrySchema = z.infer<typeof industrySchema>;

export const sourceSchema = z.object({
  selection: z.string().nonempty(),
});

export type SourceSchema = z.infer<typeof sourceSchema>;

export const marketSchema = z.object({
  id: z.number().positive(),
  market: z.string().nonempty(),
});

export type MarketSchema = z.infer<typeof marketSchema>;

export const accountDetailSchema = z.object({
  id: z.number().positive(),
  owner: owner,
  name: z.string().optional(),
  legalName: z.string().nonempty({ message: 'Legal Name required' }),
  industry: industrySchema.optional(),
  markets: marketSchema.array().optional(),
  source: sourceSchema.optional(),
  addresses: addressSchema.array().optional(),
  phoneNumbers: phoneNumberSchema.array().optional(),
  webPages: webPageSchema.array().optional(),
});

export type AccountDetailSchema = z.infer<typeof accountDetailSchema>;

export type AccountDetailErrors = SchemaErrors<AccountDetailSchema>;
